import React, { useState } from "react";

import Header from "components/Header";

import Sidebar, { SidebarItemProp } from "components/Sidebar";

import { Box, Flex } from "@chakra-ui/react";

interface DashboardProps {
  shop: any;
  sidebarItems: Array<SidebarItemProp>;
  header: any;
  children: any;
  onSidebarItemClick: (path: string) => void;
  activePath: string;
}

const Dashboard: React.FC<DashboardProps> = ({
  shop,
  children,
  header,
  sidebarItems,
  onSidebarItemClick,
  activePath,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onMenuClick = () => {
    setIsOpen(true);
  };

  const onDrawerclose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Header
        userIndex={header.userIndex}
        shops={header.shops}
        onShopChange={header.onShopChange}
        onMenuClick={onMenuClick}
        onLogout={header.onLogout}
      />
      <Flex>
        <Sidebar
          featureFlags={shop?.feature_flags || []}
          items={sidebarItems}
          activePath={activePath}
          onMenuItemClick={(path) => {
            onSidebarItemClick(path);
            setIsOpen(false);
          }}
          onDrawerclose={onDrawerclose}
          isOpen={isOpen}
        />
        <Box width="100%" height="calc(100vh - 65px)" overflowY="scroll">
          {children}
        </Box>
      </Flex>
    </>
  );
};

export default Dashboard;
