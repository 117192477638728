import React from "react";
import { useTranslation } from "react-i18next";

import HelpSrc from "./help.jpg";

import Dashboard from "containers/Dashboard";
import Container from "components/Container";
import { Box, Button, Heading, Image, VStack } from "@chakra-ui/react";

interface HelpProps {}

const Help: React.FC<HelpProps> = () => {
  const { t } = useTranslation();
  return (
    <Dashboard>
      <Container title="">
        <VStack>
          <Image src={HelpSrc} maxWidth="300px" width="90%" />
          <Box>
            <Heading marginBottom="0.5rem" size="md">
              {t("pages.help.description", "")}
            </Heading>
          </Box>
          <Box>
            <Button
              colorScheme="teal"
              as="a"
              href="https://moship.io/wa"
              target="_blank"
            >
              {t("pages.help.button", "")}
            </Button>
          </Box>
        </VStack>
      </Container>
    </Dashboard>
  );
};

export default Help;
