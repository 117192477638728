import React from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Image,
} from "@chakra-ui/react";

import MenuItem from "./MenuItem";

import { MainMenuItemProps, ChildItemProps } from "./MenuItem";

import Logo from "./images/Logo.png";

export interface SidebarItemProp {
  mainItem: MainMenuItemProps;
  childItems: Array<ChildItemProps>;
}

export interface SidebarProps {
  items: Array<SidebarItemProp>;
  onMenuItemClick: (path: string) => void;
  activePath: string;
  isOpen: boolean;
  onDrawerclose: () => void;
  featureFlags: Array<string>;
}

const Sidebar: React.FC<SidebarProps> = ({
  items,
  onMenuItemClick,
  isOpen,
  onDrawerclose,
  activePath,
  featureFlags,
}) => {
  const menuItems = items
    .filter((item) => {
      if (!item.mainItem.feature) {
        return true;
      } else if (featureFlags.includes(item.mainItem.feature)) {
        return true;
      }
    })
    .map((item, index) => {
      return (
        <MenuItem
          key={index}
          {...item}
          onItemClick={onMenuItemClick}
          activePath={activePath}
        />
      );
    });

  return (
    <>
      <Box
        display={["none", "none", "block"]}
        width="230px"
        height="calc(100vh - 65px)"
        padding="10px"
        borderRight="1px solid"
        borderRightColor="blackAlpha.300"
      >
        {menuItems}
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={() => {
          onDrawerclose();
        }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody>
            <Image marginY="1rem" src={Logo} maxWidth="150px" />
            {menuItems}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Sidebar;
