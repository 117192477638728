import React, { useEffect, useState } from "react";
import { useParams, useLocation, Redirect } from "react-router-dom";

import { useShops, useAxios, usePushPath } from "moship";
import { useShop } from "hooks";

import DashboardPresentational from "components/Dashboard";
import sidebarItems from "./menuItems";
import RemainingShipmentRibbon from "./RemainingShipmentsRibbon";

import { usePostHog } from "posthog-js/react";

interface Props {
  children?: any;
}

const Dashboard: React.FC<Props> = ({ children }) => {
  const posthog = usePostHog();
  const axios = useAxios();
  const { shop } = useShop();
  const pushPath = usePushPath();

  const [posthogIdentified, setPosthogIdentified] = useState(false);

  let location = useLocation();

  const { shops, isLoading } = useShops();
  const { userIndex } = useParams<{ userIndex: string }>();

  useEffect(() => {
    if (shops && userIndex && !posthogIdentified) {
      setPosthogIdentified(true);
      const index = parseInt(userIndex);
      const activeShop = shops[index];

      posthog?.identify(activeShop.user_username, {
        name: activeShop.user_full_name,
      });
      posthog?.group("shop", activeShop.shop_subdomain, {
        shopify_subdomain: activeShop.shop_subdomain,
      });
    }

    return () => {};
  }, [shops, userIndex, posthogIdentified, posthog]);

  if (isLoading) return null;
  if (!shops[userIndex]) return <Redirect to="/install" />;

  const currentShop = shops[userIndex];
  const dataLayer = window.dataLayer || [];

  dataLayer.push({ shopify_domain: `${currentShop.shop_subdomain}` });
  dataLayer.push({ user_distinc_id: `moshipping-${currentShop.user_id}` });
  dataLayer.push({ user_email: currentShop.user_email });
  dataLayer.push({ user_first_name: currentShop.user_first_name });
  dataLayer.push({ user_last_name: currentShop.user_last_name });
  dataLayer.push({ event: "user_info_loaded" });

  const formatedShopsArray = shops.map((shop) => {
    return {
      userFullName: shop.user_full_name,
      shopSubdomain: shop.shop_subdomain,
    };
  });

  const onShopChangeHandler = (index: number) => {
    window.open(`/${index}/`);
  };

  const onSidebarItemClick = (path: string) => {
    pushPath(path);
  };

  const onLogout = () => {
    axios
      .post("/api/session-logout/")
      .then((response) => {})
      .catch((error) => {})
      .finally(() => {
        window.location.href = "/install";
      });
  };

  const header = {
    userIndex: parseInt(userIndex),
    shops: formatedShopsArray,
    onShopChange: onShopChangeHandler,
    onLogout: onLogout,
  };

  document.title = shops[userIndex].shop_subdomain + " | Moshipping Dashboard";

  return (
    <DashboardPresentational
      shop={shop}
      header={header}
      sidebarItems={sidebarItems}
      onSidebarItemClick={onSidebarItemClick}
      activePath={location.pathname}
    >
      <RemainingShipmentRibbon />
      {children}
    </DashboardPresentational>
  );
};

export default Dashboard;
