import { useSWR } from "moship";

export const useShop = () => {
  const { data } = useSWR("/api/v1/shop/");

  return {
    shop: data,
  };
};

export const useHasFeature = () => {
  const { shop } = useShop();
  return (feature: string) => {
    return shop?.feature_flags?.includes(feature);
  };
};
